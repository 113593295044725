export default defineNuxtPlugin(() => {
  return {
    provide: {
      const: {
        administration: {
          EventTypePID: {
            /** preventivní prohlídka */
            PREVENTIVE_INSPECTION: "bdaa17c9-9a8d-42ba-82ce-6896cd0137af",
            /** revize úniku */
            LEAK_REVISION: "6691b64c-aa6d-4c16-8b17-ea545d5d9154",
            /** výměna pojistných ventilů */
            CHANGE_VALVE: "0e04cc95-b8aa-424a-9dff-41983dc04d76",
            /** revize protipožárních klapek */
            FIRE_REVISION: "59ada225-2e89-40f2-86e9-424b0a2c1f88",
            /** revize tlakových nádob - provozní */
            NEX: "8996db99-6474-411d-8528-6011486baee2",
            /** revize tlakových nádob - vnitřní */
            NEX_INTERN: "538bd276-d7e0-49bd-a91b-eb24d17e2237",
            /** revize tlakových nádob - tlaková zkouška */
            NEX_PRESSURE_TEST: "ca6d0a45-779e-4bbb-bda8-daf7d3896b34",
            /** montáž */
            ASSEMBLY: "6d2fc703-2b90-4898-ad92-8a266b66bac5",
            /** přemístění */
            RELOCATION: "ea65cd1e-7337-4053-a08a-4b7031af8fb3",
            /** plánovaná oprava */
            REPAIR: "79634c0d-4a35-41c2-84eb-e8eedf3546a5",
            /** havárie */
            CRASH: "76b67a5e-8844-46d2-8e79-0993a4f3a09f",
            /** předinstalační prohlídka */
            PRE_INSTALLATION: "6aee3116-9a5d-4f03-9338-59219ff4636d",
            /** inspekční cesta */
            INSPECTION: "e4fa5404-0e66-4416-8676-5cafd3b30978",
            /** ostatní práce */
            OTHER: "1472d303-71c9-4f52-9d9b-bafb58925da7"
          },
          OfferStatePID: {
            WIP: "d1b42cae-5c8b-422d-9e69-552a75f32d3c",
            READY: "7cacd912-369f-4a86-9445-3e392e430eb5",
            SENT: "1a676383-7982-4cb8-97f9-6a362b6da7c3",
            APPROVED: "ed505312-529d-409d-9805-ad96265a2827",
            REJECTED: "67e1a571-4992-491f-b348-8a9422562a59",
            REALISED: "bacd58d9-244f-4188-a3d5-48e6e37b2a83",
            PARTLY_REALISED: "2338bae6-8a3a-470e-b7b1-dc50c66a0273"
          },
          OutputTypePID: {
            /** nabídka */
            OFFER: "817533a3-6493-4381-84e9-a92a77e94385",
            /** žádost o vstup */
            ENTRY_REQUEST: "b3b3dd28-d4fb-4757-95ca-b8c9fc7efbb9",
            /** faktura */
            INVOICE: "7cc71722-b5e2-4c05-80a4-b628b5a01ebb",
            /** mail z události, */
            EVENT_MAIL: "263d879b-0e49-4546-be05-547bace96e4e",
            /** mezihlášení */
            CONFIRM_PROGRESS: "16bb393e-c9ca-496f-ac91-c5671507f4ec",
            /** potvrzení o přijetí */
            CONFIRM_OPEN: "c4a2aba6-e898-4d41-aec0-803a189ec666",
            /** report - havárie */
            REPORT_CRASH: "0eb17c61-9b62-4840-bffe-804ddfc0b1a6",
            /** report - montáž */
            REPORT_ASSEMBLY: "d3247be7-9f76-4f45-8c66-6405f39936b6",
            /** report - pp */
            REPORT_PP: "74169634-6699-4508-81d3-670fe9075213",
            /** report - revize */
            REPORT_REVISION: "f45cca76-a0f5-48af-ac38-7c0073c59ad6",
            /** ukončení */
            CLOSE: "c0af64a8-ba23-4182-9c44-b054d2595dad",
            /** ukončení opravy */
            CLOSE_REPAIR: "2ec6be82-7cc3-416e-9cb3-b28bedaaef61",
            /** předávací protokol */
            HANDOVER_PROTOCOL: "b29764a5-e5df-42da-be3d-a5a395a71e51"
          },
          CommunicationMethodPID: {
            EMAIL: "02c82dff-e6b3-48b2-9b2f-fe8bfc14d16a",
            PHONE: "5aec5ee5-94b1-4eca-a6e5-b95515cd5dbb",
            SYSTEM: "87db0bf2-84bf-472d-9a5a-4413227f2ea9",
            SMS: "89aa7797-2a72-47ea-b939-0c767abcd8bd",
            AUTO: "2e391f4a-d576-4a79-9664-54c73e2874de"
          },
          TaskTypeTypeId: {
            SEND_RECEIVE: 1,
            SPARE: 4,
            DISPATCH: 6,
            CENTER: 7,
            INVOICING: 8,
            OTHER: 9,
            PP: 10,
            REPAIR: 11,
            LEAK_REVISION: 12,
            INSTALLATION: 13,
            PRE_INSTALLATION: 14,
            RELOCATION: 15,
            INSPECTION: 16,
            ENTRY_REQUEST: 17,
            CLOSED: 21,
            WAITING_FOR_ENTRY: 22,
            CANCELED: 23,
            MATERIAL_WRITE_OFF: 24,
            EXCHANGE_OF_INSURANCE_VALVES: 25,
            OPERATIONAL_PRESSURE_VESSEL_INSPECTION: 26,
            INTERNAL_PRESSURE_VESSEL_INSPECTION: 27,
            PRESSURE_VESSEL_PRESSURE_TEST: 28,
            FIRE_DAMPER_INSPECTION: 29,
            MATERIAL: 30
          },
          TextType: {
            INVOICES: "7f130ef7-a927-4f50-8b74-11db6b21835e",
            ACCOMPANYING_TEXT: "a6626f99-aa31-47ac-9ff7-18f3c809ebe2"
          },
          ReportTypePID: {
            MATERIAL: "e45651bc-b9fc-4822-b861-d96f1cac43af",
            // hodinová sazba
            HOURLY_RATE: "9a357775-5b10-4caf-8f75-4d9cdc6b5200"
          },
          TaskStatusPID: {
            WAITING: "ba80442f-cbb0-477f-bd96-64e649c155c8",
            READY: "e138d318-d693-4880-8377-53942145235f",
            FINISHED: "096783ab-d8b7-4ed7-8d2f-7e5b6386db15",
            CANCELED: "001fc5fe-d94a-4a3c-82d9-db7ec94c23e8"
          },
          PartStatePID: {
            /** funkční bez závad */
            FUNCTIONAL_WITHOUT_DEFECTS: "47b38dcd-1c17-4044-b58c-827806a47c20",
            /** funkční s výhradou */
            FUNCTIONAL_WITH_RESERVATION: "2c95bded-7e8e-4e8a-bb67-fc0e262a47d5",
            /** nefunkční */
            NON_FUNCTIONAL: "e5794ef3-ca0b-4f94-9d30-1162f4f9987b",
            /** probíhá oprava */
            IN_REPAIR: "4a371cd9-a10d-4780-b388-c8a698566757"
          },
          EntryTypePID: {
            /** pl_dil_chybejici_parametr */
            MISSING_PARAMETER: "d57a9df4-5307-401d-8426-a9387ec33db4",
            /** pl_dil_stav, */
            PART_STATE: "c1dee3d4-9172-4ba8-8937-2b7d72e0c702",
            /** pl_dil_podrobnosti */
            PART_DETAILS: "97287c21-ccf8-4b15-8220-7389e6f501ef",
            /** pl_dil_znamka */
            PART_MARK: "d14ac0f5-b7c4-4ca0-b0e9-717d0dbb7e91"
          },
          EntryTypeIdentifier: {
            MISSING_PARAMETER: "pl_dil_chybejici_parametr",
            PART_STATE: "pl_dil_stav",
            PART_DETAILS: "pl_dil_podrobnosti",
            PART_MARK: "pl_dil_znamka"
          },
          TextTypePID: {
            /** mail z události */
            EVENT_MAIL: "c43ae54c-df66-429d-9366-a57921866576"
          },
          ParameterTypePID: {
            COOLANT_TYPE: "c9d897e4-2c66-4a7e-bf6a-6093b4af3edd",
            COOLANT_QUANTITY: "b10beb8d-6927-4231-ac23-38ae686ba90b",
            /** chladící výkon */
            COOLING_POWER: "36b28380-bcca-4ec4-b742-985c61865975",
            /** příkon */
            POWER: "9548442f-f11c-48ab-8d6c-4c02a4210d92",
            /** napájení */
            SUPPLY: "372452e8-3760-4ad8-80e5-bb486b0ca4cf",
            /** topný výkon */
            HEATING_POWER: "62e464e5-f2dc-47d7-96a9-9979d3bfec7b",
            /** detektor úniku */
            LEAK_DETECTOR: "87848ee8-61ef-4e6e-bca1-4c8f9d100052",
            /** připojen k okruhu */
            CONNECTED_TO_CIRCUIT: "c002eaaa-5137-43ab-8e66-45b75e94a142"
          },
          ValueTypePID: {
            BINARY: "38a0ee18-9450-45c5-8289-a17b470d85fe",
            BOOL: "7fbff32a-11db-4b7c-bc19-7489cbfc2f17",
            TIME: "f510979d-67b9-471a-91d8-980d798ada97",
            INTEGER: "b39e77fa-0dc0-4a47-9acc-c702253836fb",
            DATE: "691a9886-fbb2-4c65-b3bd-7ba3f355f02f",
            FLOAT: "b4412a69-708e-4087-b869-dc48a3a7c9c0",
            TEXT: "80ee677c-bb09-4b0d-9b88-2e725e6a435f"
          }
        },
        department: {
          HandoverProtocolState: {
            SENT: "f4705c44-4dbe-477a-8812-e3995151cbe7",
            IN_PROGRESS: "96e2a3a1-48ef-49ca-87a9-5381bde67138"
          },
          CenterCode: {
            DEPARTMENT: "DISPECINK"
          },
          OutputVariant: {
            AUTO_O2: "O2.mail",
            AUTO_DISPATCH: "dispečink.telefon"
          },
          ParameterValue: {
            /** vodní systém */
            WATER_SYSTEM: "vodní systém"
          },
          PartGroupCode: {
            JKN: "jkn",
            JKA: "jka",
            JNA: "jna",
            JPA: "jpa",
            JPO: "jpo",
            KCH: "kch",
            JSL: "jsl",
            VYP: "vyp"
          },
          Partner: {
            CETIN: "495b8d06-cef0-467d-8193-d867d5046ed4"
          }
        },
        misc: {
          NemoRecordRecordCode: {
            PART_STATE: "stav_dilu",
            MARK: "znamka",
            PART_MARK: "znamka_dilu"
          }
        }
      }
    }
  };
});
