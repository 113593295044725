import { add, type Duration } from "date-fns";
import { type GridApi, type GridCellContext } from "@olc/vue-grid";
import _, { isObject, uniq } from "lodash";

export const ROW_DETAIL_SYMBOL = "ROW-DETAIL-";

export default function useUtils () {
  const DETAIL_QUERY_SEPARATOR = "_";

  const shiftDate = (date: Date, shifts: Duration = { years: 0, days: 0, hours: 0, minutes: 0 }) => {
    return add(date, shifts);
  };

  const getContrastColor = (hex: string) => {
    // Convert hex to full-length format if it's a shortened hex code
    if (hex.length === 4) {
      hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
    }

    // Convert hex to RGB
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // Calculate relative luminance (perceived brightness)
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Choose black or white text color based on luminance
    return luminance > 0.5 ? "#000" : "#FFF";
  };

  const parseDetailQuery = (query: string, { sep = DETAIL_QUERY_SEPARATOR, keepRowDetailData = false } = {}) => {
    if (!query) {
      return [];
    }
    if (keepRowDetailData) {
      return query.split(sep);
    }
    return query.split(sep).map(id => id.replace(ROW_DETAIL_SYMBOL, ""));
  };

  const buildDetailQuery = (ids: string | string[], oldQuery?: string | string[], toggleExisting = false) => {
    const selectedDetails = typeof oldQuery === "string" ? parseDetailQuery(oldQuery) : oldQuery || [];
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    for (const id of ids) {
      if (id && !selectedDetails.includes(id)) {
        selectedDetails.push(id);
      } else if (id && toggleExisting) {
        const index = selectedDetails.indexOf(id);
        if (index >= 0) {
          selectedDetails.splice(index, 1);
        }
      }
    }
    return selectedDetails.join(DETAIL_QUERY_SEPARATOR);
  };

  function dfsPropSearch (obj: Record<string, any>, targetNames: string[]): any {
    for (const key in obj) {
      if (targetNames.includes(key)) {
        return obj[key];
      } else if (typeof obj[key] === "object") {
        const res = dfsPropSearch(obj[key], targetNames);
        if (res !== null) {
          return res;
        }
      }
    }
    return null;
  }

  function dfsPropSearchMultiple (obj: Record<string, any>, targetNames: string[]): any[] {
    const res: any[] = [];
    for (const key in obj) {
      if (targetNames.includes(key)) {
        res.push(obj[key]);
      } else if (typeof obj[key] === "object") {
        res.push(...dfsPropSearchMultiple(obj[key], targetNames));
      }
    }
    return res;
  }

  /**
   * Finds first found nested property in object by 'names'
   * @param obj root object to search in
   * @param names possible names of searched property
   * @returns object where the property is found; if not found, returns root object
   */
  const findProp = (obj: Record<string, any>, names: string[]) => {
    const res = dfsPropSearch(obj, names);
    if (res !== null) {
      return res;
    }
    return obj;
  };

  /**
   * Finds nested properties in object by 'names'
   * @param obj root object to search in
   * @param names possible names of searched property
   * @returns array of objects where the property is found; can be empty
   */
  const findPropAll = (obj: Record<string, any>, names: string[]): any[] => {
    return dfsPropSearchMultiple(obj, names);
  };

  const getGridCellValue = (ctx: GridCellContext<any>) => {
    const value = ctx.getValue();
    if (value && isObject(value)) {
      if (ctx.columnDef.columnFilterCustomData.title) {
        return (value as any)[ctx.columnDef.columnFilterCustomData?.title];
      }
      if (ctx.columnDef.columnFilterCustomData?.formField?.itemTitle) {
        return (value as any)[ctx.columnDef.columnFilterCustomData.formField.itemTitle];
      }
    }
    return value;
  };

  type Object = Record<any, any>;

  interface ObjectUpdate {
    oldValue: any;
    newValue: any;
  }

  interface ObjectDiff {
    changes: Object;
    changeCount: number;
    added: object | ObjectDiff;
    updated: {
      [propName: string]: ObjectUpdate | ObjectDiff;
    };
    removed: object | ObjectDiff;
    unchanged: object | ObjectDiff;
  }

  /**
   * @param oldObj The previous Object or Array.
   * @param newObj The new Object or Array.
   * @param deep If the comparison must be performed deeper than 1st-level properties.
   * @return A difference summary between the two objects.
   */
  function difference (oldObj: Object, newObj: Object, deep = true): ObjectDiff {
    const added: Object = {};
    const updated: Object = {};
    const removed: Object = {};
    const unchanged: Object = {};
    for (const oldProp in oldObj) {
      if (Object.hasOwn(oldObj, oldProp)) {
        const newPropValue = newObj[oldProp];
        const oldPropValue = oldObj[oldProp];
        if (Object.hasOwn(newObj, oldProp)) {
          if (newPropValue === oldPropValue) {
            unchanged[oldProp] = oldPropValue;
          } else {
            updated[oldProp] = deep && _.isObject(oldPropValue) && _.isObject(newPropValue) ? difference(oldPropValue, newPropValue, deep) : { newValue: newPropValue };
          }
        } else {
          removed[oldProp] = oldPropValue;
        }
      }
    }
    for (const newProp in newObj) {
      if (Object.hasOwn(newObj, newProp)) {
        const oldPropValue = oldObj[newProp];
        const newPropValue = newObj[newProp];
        if (Object.hasOwn(oldObj, newProp)) {
          if (oldPropValue !== newPropValue) {
            if (!deep || !_.isObject(oldPropValue)) {
              updated[newProp].oldValue = oldPropValue;
            }
          }
        } else {
          added[newProp] = newPropValue;
        }
      }
    }
    const changes = _.merge({}, added, updated, removed);
    return { changes, changeCount: Object.keys(changes).length, added, updated, removed, unchanged };
  }

  function hashCode (str: string) {
    return Array.from(str)
      .reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0)
      .toString();
  }

  function buildFormData (formData: FormData, data: any, parentKey?: string) {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      Object.keys(data).forEach((key) => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}${Array.isArray(data) ? "[]" : ""}.${key}` : key);
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey!, value);
    }
  }

  function getFormData (obj: any) {
    const formData = new FormData();
    buildFormData(formData, obj);
    return formData;
  }

  function stripHtml (html: string) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  // lodash isEmpty() is mainly for objects, arrays
  function isEmptyVal (value: any) {
    return value === undefined || value === null || Number.isNaN(value) ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0);
  }

  function prepareURLParams (url: string, params: object): string {
    const paramRegex = /{([a-zA-Z_0-9-]+)}/g;
    const urlParams = (url.match(paramRegex) || []).map(param => param.replace("{", "").replace("}", ""));
    let newURL = url;
    urlParams.forEach((param) => {
      if (params[param as keyof typeof params] === undefined || params[param as keyof typeof params] === null) {
        newURL = newURL.replace(`{${param}}/`, "");
      } else {
        newURL = newURL.replace(`{${param}}`, params[param as keyof typeof params]);
      }
    });
    return newURL;
  }

  function getUniqueSelectedRows<T extends object> (gridApi: GridApi<T>, rows?: T[]): T[] {
    if (!rows) {
      rows = gridApi.selectedRows.value.map(r => r.original);
    }
    return Array.from(uniq(rows.map(row => gridApi.config.getRowId(row))))
      .map(id => rows.find(row => gridApi.config.getRowId(row) === id))
      .filter(v => !!v) as T[];
  }

  async function refreshGridAndFormAfter (fn: Promise<any> | any, gridApi?: GridApi<any>, formRefresh?: () => void) {
    const fnRes = await fn;
    if (!fnRes) {
      formRefresh?.();
      await gridApi?.fetchData();
      gridApi?.rerender();
      // eslint-disable-next-line no-console
      console.log("REFRESH GRID AND FORM");
    }
    return fnRes;
  }

  async function forEachRow<T> (rows: T[], action: (data: T) => Promise<_ActionReturnType> | _ActionReturnType) {
    const promises = [];
    for (const row of rows) {
      promises.push(action(row));
    }
    const res = await Promise.all(promises);
    return res.find(v => typeof v === "string");
  }

  function tupleComparator (fields: string[]) {
    return (a: Record<string, any>, b: Record<string, any>) => {
      for (const field of fields) {
        if (_.get(a, field) < _.get(b, field)) { return -1; }
        if (_.get(a, field) > _.get(b, field)) { return 1; }
      }
      return 0;
    };
  }

  function isDateInPartWarranty (date: Date, partAcceptanceDate: string, partWarranty: number) {
    const acceptanceDate = parseDate(partAcceptanceDate);
    const warrantyEndDate = shiftDate(acceptanceDate, { months: partWarranty });
    return date <= warrantyEndDate;
  }

  function waitFor (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return {
    shiftDate,
    getContrastColor,
    buildDetailQuery,
    parseDetailQuery,
    ROW_DETAIL_SYMBOL,
    findProp,
    findPropAll,
    difference,
    hashCode,
    getFormData,
    getGridCellValue,
    stripHtml,
    isEmptyVal,
    prepareURLParams,
    getUniqueSelectedRows,
    refreshGridAndFormAfter,
    forEachRow,
    tupleComparator,
    isDateInPartWarranty,
    waitFor
  };
}
