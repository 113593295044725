import { format as dateFnFormat, formatISO, parse as dateFnParse } from "date-fns";

export const DATE_FORMAT = "dd.MM.yyyy";
export const DATETIME_FORMAT = "dd.MM.yyyy HH:mm";
export const TIME_FORMAT = "HH:mm";
export const TIME_FORMAT_FULL = "HH:mm:ss";

export const parseDate = (formattedDate: string) => {
  return dateFnParse(formattedDate, DATE_FORMAT, new Date());
};

export const parseDatetime = (formattedDatetime: string) => {
  return dateFnParse(formattedDatetime, DATETIME_FORMAT, new Date());
};

export const parseTime = (formattedTime: string) => {
  let dateValue = null;
  try {
    dateValue = dateFnParse(formattedTime, TIME_FORMAT_FULL, new Date());
    if (isNaN(dateValue.getTime())) {
      throw new TypeError("x");
    }
  } catch {
    try {
      dateValue = dateFnParse(formattedTime, TIME_FORMAT, new Date());
    } catch {}
  }
  if (dateValue && isNaN(dateValue.getTime())) {
    dateValue = null;
  }
  return dateValue;
};

export default function useFormat () {
  const formatBool = (bool?: boolean | null, displayNull = false, nullValue: any = "-") => {
    if (displayNull && (bool === null || bool === undefined)) {
      return nullValue;
    }
    return bool ? "Ano" : "Ne";
  };

  const formatNumber = (number?: number | string | null, precision = 1) => {
    number = parseFloat(number as string);
    if (number === null || number === undefined || isNaN(number)) {
      return "";
    }
    return Number.isInteger(number) ? number.toString() : number.toFixed(precision);
  };

  const formatPostCode = (postCode?: string | null) => {
    if (!postCode) {
      return "";
    }
    // remove all non-digit characters from the string except for one space
    return postCode.replace(/\D+/g, "").replace(/^(\d{3})(\d{1,2}).*/, "$1 $2");
  };

  /** Like: 2011-03-21T12:15:48.595000+01:00 */
  const formatDatetimeISO = (datetime: Date) => {
    return formatISO(datetime, { format: "extended" });
  };

  const formatDate = (date?: string | Date | null) => {
    if (!date) {
      return "";
    }
    const dateValue = date instanceof Date ? date : new Date(date);
    if (isNaN(dateValue.getTime())) {
      // eslint-disable-next-line no-console
      console.error(`Invalid date value '${date}'`);
      return date.toString();
    }
    return dateFnFormat(date instanceof Date ? date : new Date(date), DATE_FORMAT);
  };

  const formatDatetime = (datetime?: string | Date | null) => {
    if (!datetime) {
      return "";
    }
    const dateValue = datetime instanceof Date ? datetime : new Date(datetime);
    if (isNaN(dateValue.getTime())) {
      // eslint-disable-next-line no-console
      console.error(`Invalid datetime value '${datetime}'`);
      return datetime.toString();
    }

    try {
      return dateFnFormat(dateValue, DATETIME_FORMAT);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Tried to format date value '${dateValue}' as datetime, formatting as date...\n(${e})`);
      return dateFnFormat(dateValue, DATE_FORMAT);
    }
  };

  const formatTime = (date?: string | Date | null) => {
    if (!date) {
      return "";
    }
    if (date instanceof Date) {
      if (isNaN(date.getTime())) {
        // eslint-disable-next-line no-console
        console.error(`Invalid time value '${date}'`);
        return date.toString();
      }
      return dateFnFormat(date, TIME_FORMAT);
    }

    const dateValue = parseTime(date);
    if (!dateValue) {
      // eslint-disable-next-line no-console
      console.error(`Invalid time value '${date}'`);
      return date.toString();
    }
    return dateFnFormat(dateValue, TIME_FORMAT);
  };

  const formatTimeUnit = (timeUnit: string | number | undefined) : string => {
    timeUnit = parseInt(timeUnit as string);
    if (!timeUnit || isNaN(timeUnit)) {
      return "";
    }

    switch (timeUnit) {
    case 15:
      return "čtvrthodina";
    case 30:
      return "půlhodina";
    case 60:
      return "hodina";
    default:
      if (timeUnit === 1) {
        return "1 minuta";
      } else if (timeUnit < 5) {
        return `${timeUnit} minuty`;
      } else {
        return `${timeUnit} minut`;
      }
    }
  };

  function getValueFormatter (type?: FormattedType | null): (value: any) => any {
    switch (type) {
    case "bool":
      return formatBool;
    case "boolean":
      return formatBool;
    case "number":
      return formatNumber;
    case "date":
      return formatDate;
    case "datetime":
      return formatDatetime;
    case "time":
      return formatTime;
    case "gps1":
      return (value: number) => formatGps(value, "N");
    case "gps2":
      return (value: number) => formatGps(value, "E");
    default:
      return (value: any) => value;
    }
  }

  const formatGps = (floatNumber: any, prefix?: string): string => {
    if (isNaN(floatNumber) || !isFinite(floatNumber)) {
      return "";
    }

    const degrees = Math.floor(floatNumber);
    const minutesFloat = (floatNumber - degrees) * 60;
    const minutes = Math.floor(minutesFloat);
    const seconds = (minutesFloat - minutes) * 60;

    return `${prefix || ""}${degrees.toString().padStart(2, "0")}° ${minutes.toString().padStart(2, "0")}' ${seconds.toFixed(2).toString().padStart(5, "0")}"`;
  };

  const formatFilepath = (path?: string | null) => {
    if (!path) {
      return "";
    }
    const tryUnix = path.split("/").pop();
    if (tryUnix === path) {
      return path.split("\\").pop();
    }
    return tryUnix;
  };

  const formatFile = (file?: File | string | null) => {
    if (!file) {
      return "";
    }
    if (file instanceof File) {
      return file.name;
    }
    return formatFilepath(file);
  };

  return { DATE_FORMAT, DATETIME_FORMAT, TIME_FORMAT, TIME_FORMAT_FULL, formatBool, formatNumber, formatPostCode, formatDatetimeISO, formatDate, formatDatetime, formatTimeUnit, getValueFormatter, formatGps, formatFilepath, formatFile, formatTime };
}
