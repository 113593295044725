<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <svg id="pen-slash-hack" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.307 16.011" class="icon icon--pen-slash-light"><path d="M12.69 1.237a.75.75 0 0 1 1.061 0l1.328 1.329a.75.75 0 0 1 0 1.061l-1.707 1.717-2.4-2.4Zm-2.421 2.418 2.4 2.4L5.3 13.413a2 2 0 0 1-.851.5l-2.9.857.854-2.9a2 2 0 0 1 .5-.851ZM14.461.526a1.75 1.75 0 0 0-2.478 0l-9.786 9.783a3 3 0 0 0-.757 1.277L.329 15.372a.5.5 0 0 0 .619.619l3.786-1.114a3 3 0 0 0 1.277-.757l9.783-9.783a1.75 1.75 0 0 0 0-2.478Z" /><path d="m15 15.708-15-15L.708 0l15 15Z" data-name="Line 99" /></svg>
</template>

<script setup lang="ts">
import "@olc/vue-loader/dist/style.css";
import "@vuepic/vue-datepicker/dist/main.css";

useHead({
  link: [
    { rel: "icon", type: "image/svg+xml", href: "/favicon.svg" }
  ]
});
</script>

<style itemscope>
i.v-icon svg.icon + svg.icon {
  display: none;
}

button.v-btn--active svg:has(path#pen-light) {
  background: -moz-element(#pen-slash-hack) no-repeat; /* Firefox */
}

#pen-slash-hack {
  z-index: -1;
  position: fixed;
  width: 16px;
  height: 16px;
  color: rgb(var(--v-theme-iconPrimary));
}

</style>
