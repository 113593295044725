import { isEmpty, merge } from "lodash-es";
import type { Technician, Vehicle } from "~/components/service";

export default function () {
  const runtimeConfig = useRuntimeConfig();
  const localePath = useLocalePath();
  const { $i18n } = useNuxtApp();
  const route = useRoute();
  const authStore = useAuthStore();
  const { prepareURLParams } = useUtils();

  const baseUrl = computed<string>(() => process.client ? runtimeConfig.public.baseApiUrl : "http://api:8000");

  async function simpleCall<P extends APIPath, M extends APIPathMethod<P>> (
    ...[url, method, params, body, options]: CallArgs<P, M>
  ) {
    const internalOptions: object = {
      headers: {
        "Accept-Language": $i18n.locale.value,
        accept: "application/json"
      },
      method
    };

    const mergedOptions = merge(internalOptions, options ?? {});

    return await useFetch(prepareURLParams(url, (params as any)?.path), {
      ...mergedOptions,
      body: !body || isEmpty(body) ? undefined : body,
      query: params?.query,
      baseURL: baseUrl.value
    }) as CallResponse<P, M>;
  }

  async function call<P extends APIPath, M extends APIPathMethod<P>> (
    ...[url, method, params, body, options, noRedirectOn401]: CallArgs<P, M>
  ) {
    if (authStore.isAccessTokenExpired()) {
      await authStore.refreshAccessToken();
    }
    if (authStore.accessToken) {
      options = merge(options || {}, { headers: { Authorization: authStore.accessTokenHeader } });
    }

    const res = await simpleCall<P, M>(url, method, params, body, options);

    if (res.error.value && [401, 403].includes(res.error.value.statusCode ?? 0) && !noRedirectOn401) {
      navigateTo(localePath({ name: "login", query: { next: route.fullPath } }));
    }
    return res as CallResponse<P, M>;
  }

  function getCacheKey (url: any, method: any, params?: Record<string, any>, body?: Record<string, any>) {
    return `${url}_${method}_${JSON.stringify(params)}_${JSON.stringify(body)}`;
  }
  const savedCallKeys: string[] = [];
  function resetCallCache () {
    clearNuxtData(savedCallKeys);
  }

  async function cachedCall<P extends APIPath, M extends APIPathMethod<P>> (
    ...[url, method, params, body, options]: CallArgs<P, M>
  ) {
    const data = ref<any>(null);
    const error = ref<any>(null);

    const cacheKey = getCacheKey(url, method, params, body as Record<string, any>);
    const { data: nuxtData } = useNuxtData(cacheKey);

    if (!nuxtData.value || (!nuxtData.value.data && !nuxtData.value.loading)) {
      nuxtData.value = {
        data: null,
        loading: true,
        onLoadListeners: []
      };

      const res = await call(url, method, params, body, options);
      savedCallKeys.push(cacheKey);

      const listeners = nuxtData.value.onLoadListeners;

      nuxtData.value = {
        data: res.data.value,
        loading: false
      };
      data.value = nuxtData.value.data;

      if (res.error.value) {
        error.value = res.error.value;
      }

      for (const listener of listeners) {
        listener();
      }
    } else if (nuxtData.value.loading) {
      data.value = await new Promise((resolve) => {
        nuxtData.value.onLoadListeners.push(() => {
          resolve(nuxtData.value.data);
        });
      });
    } else {
      data.value = nuxtData.value.data;
    }

    return { data, error } as CallResponse<P, M>;
  }

  async function fetchPdf<P extends APIPath> (
    type: "open" | "download" = "open",
    filename = "file",
    url?: P,
    params?: _CallParamsParams<P, APIPathMethod<P>>,
    body?: _CallParamsBody<P, APIPathMethod<P>>,
    options: object = {}
  ) {
    if (!url) {
      const loc = useRequestURL().hostname.replace("is", "api");
      url = `https://${loc}/api/pdf${route.fullPath}/` as P;
      url = "" as P;
    }

    const callRes = await call(url, "post" as APIPathMethod<P>, params, body, options);
    if (callRes.error.value) {
      if (!(options as any).suppressError) {
        useNotifier().error("load");
      }
      return callRes;
    }

    const blob = new Blob([callRes.data.value as any], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(blob);

    try {
      if (type === "open") {
        window.open(pdfUrl, "_blank");
      } else {
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.download = `${filename?.replace(".pdf", "") ?? "file"}.pdf`;
        a.click();
        URL.revokeObjectURL(pdfUrl);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    return callRes;
  }

  async function callWithFiles<P extends APIPath, M extends APIPathMethod<P>> (
    ...[url, method, params, body, options, noRedirectOn401]: CallArgs<P, M>
  ) {
    const { getFormData } = useUtils();
    let internalOptions: object = {
      headers: {
        "Accept-Language": $i18n.locale.value,
        accept: "application/json"
      },
      method
    };

    if (authStore.isAccessTokenExpired()) {
      await authStore.refreshAccessToken();
    }
    if (authStore.accessToken) {
      internalOptions = merge(internalOptions, { headers: { Authorization: authStore.accessTokenHeader } });
    }

    const mergedOptions = merge(internalOptions, options);

    // call (useFetch) is not setting Content-Type header correctly
    const res: { data: any, error: any } = { data: ref(null), error: ref(null) };

    await $fetch(prepareURLParams(url, (params as any)?.path), {
      ...mergedOptions,
      body: !body || isEmpty(body) ? undefined : getFormData(body),
      query: params?.query,
      baseURL: baseUrl.value
    }).catch((err) => {
      res.error.value = err;
    }).then((data) => {
      res.data.value = data;
    });

    if (res.error.value && [401, 403].includes(res.error.value.statusCode ?? 0) && !noRedirectOn401) {
      navigateTo(localePath({ name: "login", query: { next: route.fullPath } }));
    }
    return res as CallResponse<P, M>;
  }

  const modules = {
    auth: {
      // async tokenVerify (token: string) {
      //   return await call("/api/auth/token/verify/", "post", {}, { token });
      // },
      async tokenRefresh (accessToken: string, refreshToken: string) {
        return await simpleCall("/api/auth/token/refresh/", "post", {}, { access: accessToken, refresh: refreshToken });
      },
      async verifyOtp (otp: string) {
        return await call("/api/user/otp-verify/", "post", {}, { otp });
      }
    },
    user: {
      async login (username: string, password: string) {
        return await simpleCall("/api/user/login/", "post", {}, { username, password });
      },
      async getSession () {
        return await call("/api/user/session/", "get", {}, {});
      },
      async logout () {
        return await call("/api/user/logout/", "post", {}, { refresh_token: useAuthStore()!.refreshToken as string });
      },
      async changePassword (newPass: string, password: string) {
        return await call("/api/user/change-password/", "post", {}, { new_pass: newPass, password });
      },
      async getOtpQR (oneStepToken?: string) {
        return await call("/api/user/mfa-secret-qr/", "get", { query: oneStepToken ? { token: oneStepToken } : {} }, {});
      },
      async getBackupCodes () {
        return await call("/api/user/backup-codes/", "get", {}, {});
      },
      async generateBackupCodes () {
        return await call("/api/user/backup-codes/", "post", {}, {});
      },
      async resetMfa () {
        return await call("/api/user/reset-mfa/", "post", {}, {});
      }
    },
    service: {
      // pro servis/absence/techniku/prehled
      async getTechnicianAbsencesOverview (body: { [key: string]: any } = { filters: [] }) {
        return await call("/api/service/absence/technician/overview/", "post", {}, body as any);
      },
      async getTechniciansOverview (body: { [key: string]: any } = { filters: [] }) {
        return await call("/api/department/technician/overview/", "post", {}, body as any);
      },
      async createTechnicianAbsence (body: {date: string, technician: Technician, reason: string, note: string}) {
        return await call("/api/service/absence/technician/create/", "post", {}, body);
      },
      async deleteTechnicianAbsence (id: string) {
        return await call("/api/service/absence/technician/delete/{id}/", "delete", { path: { id } });
      },
      // pro servis/absence/vozidel/prehled
      async getVehicleAbsencesOverview (body: { [key: string]: any } = { filters: [] }) {
        return await call("/api/service/absence/vehicle/overview/", "post", {}, body as any);
      },
      async getVehicleOverview (body: { [key: string]: any } = { filters: [] }) {
        return await call("/api/department/vehicle/overview/", "post", {}, body as any);
      },
      async createVehicleAbsence (body: {date: string, vehicle: Vehicle, reason: string, note: string}) {
        return await call("/api/service/absence/vehicle/create/", "post", {}, body);
      },
      async deleteVehicleAbsence (id: string) {
        return await call("/api/service/absence/vehicle/delete/{id}/", "delete", { path: { id } });
      },
      // pro servis/technici
      async getTechnicianActions (body: {
        technician_id: string,
        start_date: string,
        end_date: string
      }) {
        return await call("/api/department/departure/technician/", "post", {}, body);
      },
      //
      async getNotes () {
        return await call("/api/department/note/service/", "get", {}, {});
      },
      async saveNotes (subject?: any, time?: any, note?: string, notedBy?: any) {
        return await call("/api/department/note/service/", "post", {}, { subject, time, note, noted_by: notedBy });
      },
      // pro servis/vyjezdy
      async getDepartureOverview () {
        return await call("/api/service/departure/overview/", "get", {}, {});
      },
      // pro servis sluzby
      async getServiceTechniciansShiftsData () {
        return await call("/api/service/shifts/overview/", "post", {}, {});
      },
      // async getServicesActionData () {
      //   return await call("/api/service/shifts/data/", "post", {}, {});
      // }
      async getServicesActionData () {
        return await call("/api/service/shifts/data/", "get", {}, {});
      }
    },
    coolant: {
      async getNewCoolant () {
        return await call("/api/service/coolant/state/new/", "get", {}, {});
      },
      async getAspiratedCoolant () {
        return await call("/api/service/coolant/state/aspirated/", "get", {}, {});
      }
    },
    mail: {
      sendMail (mailData: SendMailRequestBody) {
        return callWithFiles("/api/department/documentation/send/", "post", {}, {
          contacts: mailData.contacts,
          subject: mailData.subject,
          message: mailData.message,
          filepath_attachments: mailData.filepath_attachments,
          file_attachments: mailData.file_attachments
        });
      }
    }
  };

  return {
    prepareURLParams,
    call,
    getCacheKey,
    resetCallCache,
    cachedCall,
    simpleCall,
    fetchPdf,
    callWithFiles,
    baseUrl,
    ...modules
  };
}
